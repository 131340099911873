// creamos el pathname y la URL que se muestra en el browser a partir de los filtros activos
export default function createQueryURL(newQuery) {
  const { gender, slugs, talle, ordenar, keywords, landing, page } = newQuery;
  let newUrl = '';
  if (landing) {
    newUrl += `/${landing}`;
  }

  // si el filtro de gender esta aplicado, insertamos en la URL el gender
  // y tambien vamos armando el pathname
  if (gender) {
    newUrl += `/${gender}`;
  }

  // IDEM
  if (slugs?.length > 0) {
    newUrl += `/${slugs.join('/')}`;
  }

  // este array va a guardar los filtros que van a pasar a ser URL query
  const query = [];

  if (ordenar) {
    newUrl += `/o/${ordenar}`;
  }
  if (keywords) {
    newUrl += `/k/${keywords}`;
  }
  if (talle?.length > 0) {
    // al hacer .toString se van a separar por "," porque es un array
    if (newUrl[newUrl.length - 1] !== '/') newUrl += '/';
    newUrl += `t/${talle.toString()}`;
  }
  newUrl += `/p/${page || 1}`;

  // verificamos si hay almenos 1 query, si no directamente no lo agregamos a la URL
  if (query.length > 0) {
    // creamos el pathname y URL final
    // separamos cada query en el array por "&"
    newUrl = `${newUrl}?${query.join('&')}`;
  }

  if (!gender && slugs.length === 0 && !landing) {
    newUrl = `/productos/${newUrl}`;
  }
  return {
    newUrl
  };
}
